<template>
  <div v-if="!isLoading">
    <b-row class="mt-2">
      <b-col class="field-name-class" cols="6">
        {{ $t('components.custom.planning.booking-details-modal.general-information.duration') }}
      </b-col>
      <b-col class="field-name-class" cols="6">
        {{ $t('components.custom.planning.booking-details-modal.general-information.nbPlaces') }}
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col cols="3">
        <d-text-field
          v-model="duration"
          class-name="background-light-blue-inputs"
          size="sm"
          type="number"
          min="1"
        />
      </b-col>
      <b-col
        class="ml-0 pl-0 d-flex align-items-center align-content-center mt-1"
        cols="3"
      >
        <label class="input-near-label">
          {{ $t('minutes') }}
        </label>
      </b-col>
      <b-col cols="3">
        <d-text-field
          v-model="booking.maxParticipantsCountLimit"
          class-name="background-light-blue-inputs"
          size="sm"
          type="number"
          min="1"
        />
      </b-col>
      <b-col
        class="ml-0 pl-0 d-flex align-items-center align-content-center mt-1"
        cols="3"
      >
        <label class="input-near-label">
          {{ $t('places') }}
        </label>
      </b-col>
    </b-row>
    <b-row class="mt-2" v-if="innerWidth">
      <b-col class="field-name-class" cols="6">
        {{ $t('components.custom.planning.booking-details-modal.general-information.online-booking') }}
      </b-col>
      <b-col class="field-name-class" cols="6">
        {{ $t('components.custom.planning.booking-details-modal.general-information.hours-before-booking') }}
      </b-col>
    </b-row>
    <b-row class="mt-2" v-if="innerWidth">
      <b-col cols="6">
        <select
          v-model="booking.registrationAvailableOnline"
          class="form-control background-light-blue-inputs"
          size="sm"
        >
          <option :value="true"> {{ $t('general.actions.yes') }}</option>
          <option :value="false"> {{ $t('general.actions.no') }}</option>
        </select>
      </b-col>
      <b-col cols="3">
        <d-text-field
          v-model="booking.registrationTimeBeforeStart"
          class-name="background-light-blue-inputs"
          size="sm"
          type="number"
          min="1"
        />
      </b-col>
      <b-col
        class="ml-0 pl-0 d-flex align-items-center align-content-center mt-1"
        cols="3"
      >
        <label class="input-near-label">
          {{ $t('hours') }}
        </label>
      </b-col>
    </b-row>
  </div>
</template>
<script>

import {isNotUndefinedAndNotNull} from "@/utils/classes";
import {getPricesPlayground} from "@api/doinsport/services/timetable/blocks/price/price.api";
import {FORMULA_TYPE, LEISURE_TYPE, LESSON_TYPE, SPORT_TYPE} from "@/classes/doinsport/Price";

export default {
  data: () => ({
    duration: null,
  }),
  props: {
    booking: {
      type: Object,
      default: this
    }
  },
  watch: {
    duration: function () {
      this.updateDuration(this.duration);
    },
    blockPrice: {
      deep: true,
      handler() {
        if (this.$route.name === 'booking_new_index') {
          if ("undefined" !== typeof this.blockPrice) {
            this.updateBookingData();
          }
        }
      }
    }
  },
  computed: {
    isLoading() {
      return this.$store.getters["playgrounds/getLoader"];
    },
    blockPrices() {
      return this.$store.getters["playgrounds/getSelectedBlockPrices"];
    },
    blockPrice() {
      return this.blockPrices.find(el => el['@id'] === this.booking.timetableBlockPrice);
    },
    innerWidth() {
      return this.$store.getters['layout/getInnerWidth'] > 900;
    }
  },
  methods: {
    updateBookingData() {
      console.log(this.blockPrice, '------');

      this.updateDuration(this.blockPrice.duration / 60);
      this.booking.maxParticipantsCountLimit = this.blockPrice.maxParticipantsCountLimit;
      this.booking.registrationAvailableOnline = this.blockPrice.registrationAvailableOnline;
      this.booking.registrationTimeBeforeStart = this.blockPrice.registrationTimeBeforeStart / 3600;

      this.$bus.$emit('on:block-prices-reloaded');
    },
    updateDuration(duration) {
      if (duration) {
        const endAt = this.$moment(this.booking.startAt).clone();
        this.booking.endAt = endAt.add(duration, 'minutes');
        this.duration = duration;
      }
    },
    loadTimetableBlockPrices() {
      this.$store.dispatch('playgrounds/setLoader', true);

      if (this.booking.playgrounds.length > 0 && this.booking.activity) {
        getPricesPlayground(this.booking.playgrounds[0].replace('/clubs/playgrounds/', ''), this.booking.activity.replace('/activities/', ''), [FORMULA_TYPE, LEISURE_TYPE, LESSON_TYPE, SPORT_TYPE])
          .then(resp => {
            this.$store.dispatch('playgrounds/saveBlockPricesSelection', resp.data['hydra:member']);
            this.$store.dispatch('playgrounds/setLoader', false);
          })
          .finally(() => {
            this.initInputs();
            this.$bus.$emit('on:playground-block-prices:loaded');
          })
        ;
      }
    },
    initInputs() {
      if (this.$route.params.id) {
        this.duration = this.$moment(this.booking.endAt).diff(this.booking.startAt, 'minutes');

        this.$bus.$emit('on:block-prices-reloaded');
      } else {
        if (isNotUndefinedAndNotNull(this.blockPrice)) {
          this.updateBookingData();
        }
      }
    },
  },
  created() {
    this.loadTimetableBlockPrices();
  },
  mounted() {
    this.$bus.$on('reload:lessons-block-prices', this.loadTimetableBlockPrices);
    this.$bus.$on('on:date-time-pickers:update', () => {
      const endAt = this.$moment(this.booking.endAt);
      const startAt = this.$moment(this.booking.startAt);

      this.duration = endAt.diff(startAt, 'minutes');
    });
  },
  beforeDestroy() {
    try {
      this.$bus.$off('reload:lessons-block-prices');
      this.$bus.$off('on:date-time-pickers:update');
    } catch (e) {
    }
    this.$store.dispatch('playgrounds/saveBlockPricesSelection', []);
  }
}
</script>
<style scoped lang="scss">
.input-near-label {
  font: normal normal normal 14px Avenir;
}
</style>
